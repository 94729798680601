import React, { useState } from "react";
import "./Faq.css";

const Faq = () => {
  // State to track which FAQ items are expanded
  const [expandedItems, setExpandedItems] = useState({});

  // Toggle expanded state for an FAQ item
  const toggleItem = (id) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // FAQ data
  const faqItems = [
    {
      id: 1,
      question: "Які розміри картин ви пропонуєте?",
      answer:
        "Ми пропонуємо широкий асортимент розмірів картин - від невеликих 20x30 см до великих форматів 100x150 см. Ви можете обрати розмір, який найкраще підходить для вашого інтер'єру. Перегляньте наш повний каталог розмірів у розділі 'Розміри/Ціни'.",
    },
    {
      id: 2,
      question: "Скільки часу займає виготовлення картини?",
      answer:
        "Зазвичай виготовлення картини займає 1-2 робочі дні залежно від складності замовлення. Для термінових замовлень ми можемо прискорити процес - просто повідомте нас про ваші потреби, і ми зробимо все можливе, щоб задовольнити ваш запит.",
    },
    {
      id: 3,
      question: "Чи можу я замовити картину з власної фотографії?",
      answer:
        "Так, звичайно! Ми спеціалізуємось на створенні картин з фотографій наших клієнтів. Ви можете завантажити свою фотографію під час оформлення замовлення, і наші художники перетворять її на прекрасну картину в обраному вами стилі.",
    },
    {
      id: 4,
      question: "Якими способами я можу оплатити замовлення?",
      answer:
        "Ми пропонуємо кілька зручних способів оплати: банківський переказ на картку, оплата при отриманні (післяплата) або готівкою при самовивозі з нашого офісу в Києві. Перед початком виготовлення картини ми просимо внести передоплату в розмірі 200 грн.",
    },
    {
      id: 5,
      question: "Як здійснюється доставка замовлень?",
      answer:
        "Доставка здійснюється через сервіси 'Нова Пошта', 'Укрпошта', 'Meest' або будь-якого іншого зручного для вас перевізника. Для великих розмірів картин ми рекомендуємо кур'єрську доставку до дверей, оскільки деякі відділення мають обмеження на розмір вантажу.",
    },
    {
      id: 6,
      question:
        "Чи можу я повернути замовлення, якщо воно мені не сподобається?",
      answer:
        "Відповідно до законодавства України, ви маєте право повернути якісний товар протягом 14 днів з моменту отримання, якщо він не використовувався і збережений його товарний вигляд. Проте, оскільки наші картини виготовляються на індивідуальне замовлення з використанням ваших фотографій, вони не підлягають поверненню, якщо відповідають замовленим параметрам.",
    },
    {
      id: 7,
      question: "Які стилі картин ви пропонуєте?",
      answer:
        "Ми пропонуємо різноманітні стилі: шайн-арт, ілюстрації на фото, живопис, історичні стилі, заміна фону, колажі, мультяшний стиль, реалізм та інші. Перегляньте наш повний каталог стилів у розділі 'Стилі' на нашому сайті.",
    },
    {
      id: 8,
      question: "Чи можна додати додаткові об'єкти на мою картину?",
      answer:
        "Так, наші художники можуть додати різноманітні додаткові об'єкти на вашу картину - від простих елементів декору до складних композицій. Вартість додавання об'єктів залежить від їх складності та кількості.",
    },
    {
      id: 9,
      question: "Що таке текстурний лак і навіщо він потрібен?",
      answer:
        "Текстурний лак - це спеціальне покриття, яке наноситься на картину, щоб надати їй об'ємних ефектів та захистити від впливу навколишнього середовища. Він створює ефект мазків пензля та додає картині глибину і текстуру, що робить її більш виразною та схожою на класичний живопис.",
    },
    {
      id: 10,
      question: "Чи можна замовити картину без промальовки?",
      answer:
        "Так, ми пропонуємо варіант замовлення без художньої промальовки. У цьому випадку ваше фото буде надруковане на полотні у вихідному вигляді, без внесення художніх правок. Це хороший варіант, якщо у вас вже є готове фото високої якості.",
    },
  ];

  return (
    <div className="faq-container">
      <h1 className="faq-title">Часті запитання</h1>
      <p className="faq-subtitle">
        Відповіді на поширені запитання про наші послуги та продукцію
      </p>

      <div className="faq-items">
        {faqItems.map((item) => (
          <div className="faq-item" key={item.id}>
            <div
              className={`faq-question ${expandedItems[item.id] ? "active" : ""}`}
              onClick={() => toggleItem(item.id)}
            >
              <h3>{item.question}</h3>
              <span className="faq-icon">
                {expandedItems[item.id] ? "-" : "+"}
              </span>
            </div>
            <div
              className={`faq-answer ${expandedItems[item.id] ? "active" : ""}`}
            >
              <p>{item.answer}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="faq-contact-section">
        <h2>Не знайшли відповідь на своє запитання?</h2>
        <p>
          Зв'яжіться з нами зручним для вас способом, і ми з радістю допоможемо:
        </p>
        <div className="faq-contact-methods">
          <a
            href="https://t.me/ARTME_holst"
            className="faq-contact-link telegram"
          >
            <img src="/img/icons/tg.png" alt="Telegram" />
            <span>Telegram</span>
          </a>
          <a href="#" className="faq-contact-link viber">
            <img src="/img/icons/vber.png" alt="Viber" />
            <span>Viber</span>
          </a>
          <a
            href="https://instagram.com/artme_holst"
            className="faq-contact-link viber"
          >
            <img src="/img/icons/ig.png" alt="Instagram" />
            <span>Instagram</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Faq;
