import React from "react";
import "./Delivery.css";

const Delivery = () => {
  return (
    <div className="delivery-container">
      <div className="delivery-header">
        <img
          src="/img/Logo_girl_trans.png"
          alt="ArtMe Holst Logo"
          className="delivery-logo"
        />
        <h1>Доставка та оплата</h1>
        <p className="delivery-subtitle">
          Інформація про способи доставки та оплати замовлень
        </p>
      </div>

      <div className="delivery-section">
        <h2>Способи доставки</h2>

        <div className="delivery-option">
          <div className="delivery-icon nova-poshta"></div>
          <div className="delivery-details">
            <h3>Нова Пошта</h3>
            <p>
              Доставка у відділення або поштомат по всій Україні. Вартість
              доставки згідно з тарифами перевізника.
            </p>
            <p className="delivery-note">
              Примітка: Картини великих розмірів (від 60х80 см) можуть не
              прийматися вашим вказаним відділенням через обмеження на габарити
              вантажу. Для таких замовлень рекомендуємо доставку кур'єром до
              дверей.
            </p>
          </div>
        </div>

        <div className="delivery-option">
          <div className="delivery-icon ukrposhta"></div>
          <div className="delivery-details">
            <h3>Укрпошта</h3>
            <p>
              Доставка у відділення по всій Україні. Вартість доставки згідно з
              тарифами перевізника.
            </p>
          </div>
        </div>

        <div className="delivery-option">
          <div className="delivery-icon meest"></div>
          <div className="delivery-details">
            <h3>Meest</h3>
            <p>
              Доставка у відділення або поштомат по всій Україні. Вартість
              доставки згідно з тарифами перевізника.
            </p>
          </div>
        </div>

        <div className="delivery-option">
          <div className="delivery-icon courier"></div>
          <div className="delivery-details">
            <h3>Доставка службою таксі (м. Київ)</h3>
            <p>
              Швидка доставка по місту Києву протягом 2-3 годин. Вартість
              доставки розраховується згідно з тарифами служби таксі.
            </p>
          </div>
        </div>

        <div className="delivery-option">
          <div className="delivery-icon pickup"></div>
          <div className="delivery-details">
            <h3>Самовивіз</h3>
            <p>
              Ви можете самостійно забрати замовлення з нашого офісу за адресою:
              м. Київ, вул. Миколи Голего, 7-В.
            </p>
            <p>Графік роботи: Пн-Сб з 9:00 до 21:00, Нд - вихідний.</p>
          </div>
        </div>
      </div>

      <div className="delivery-section">
        <h2>Способи оплати</h2>

        <div className="payment-option">
          <div className="payment-icon card"></div>
          <div className="payment-details">
            <h3>Банківський переказ</h3>
            <p>Оплата через банківський додаток шляхом переказу на картку.</p>
            <p>
              Після оформлення замовлення ми надішлемо реквізити для оплати
              (номер карти або IBAN).
            </p>
          </div>
        </div>

        <div className="payment-option">
          <div className="payment-icon cod"></div>
          <div className="payment-details">
            <h3>Накладений платіж (післяплата)</h3>
            <p>
              Ви можете оплатити замовлення при отриманні у відділенні
              перевізника.
            </p>
            <p className="payment-note">
              Примітка: При оформленні замовлення з оплатою через накладений
              платіж необхідно внести передоплату в розмірі 200 грн.
            </p>
          </div>
        </div>

        <div className="payment-option">
          <div className="payment-icon cash"></div>
          <div className="payment-details">
            <h3>Готівка (при самовивозі)</h3>
            <p>
              Ви можете оплатити замовлення готівкою при отриманні в нашому
              офісі.
            </p>
          </div>
        </div>
      </div>

      <div className="delivery-section">
        <h2>Терміни виготовлення та доставки</h2>

        <div className="timeline">
          <div className="timeline-item">
            <div className="timeline-icon production"></div>
            <div className="timeline-content">
              <h3>Виготовлення</h3>
              <p>
                1-2 робочі дні з моменту підтвердження замовлення та оплати.
              </p>
            </div>
          </div>

          <div className="timeline-item">
            <div className="timeline-icon shipping"></div>
            <div className="timeline-content">
              <h3>Відправка</h3>
              <p>
                Відправка здійснюється протягом 1 робочого дня після
                виготовлення картини.
              </p>
            </div>
          </div>

          <div className="timeline-item">
            <div className="timeline-icon delivery"></div>
            <div className="timeline-content">
              <h3>Доставка</h3>
              <p>
                Терміни доставки залежать від обраного перевізника та регіону
                доставки:
              </p>
              <ul>
                <li>Нова Пошта: 1-2 дні</li>
                <li>Укрпошта: 3-7 днів</li>
                <li>Meest: 2-3 дні</li>
                <li>Таксі (м. Київ): 2-3 години</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="delivery-notes">
        <h2>Важлива інформація</h2>
        <ul>
          <li>
            Всі замовлення ретельно пакуються для безпечного транспортування.
          </li>
          <li>
            Картини великих розмірів рекомендуємо доставляти кур'єрською службою
            або самовивозом.
          </li>
          <li>
            Для оформлення замовлення з доставкою необхідно вказати точну адресу
            доставки та контактний телефон.
          </li>
          <li>
            У разі питань щодо доставки або оплати, звертайтеся за телефонами:
            097xxxxxxx, 063xxxxxxx.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Delivery;
