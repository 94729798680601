import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./PriceCalculator.css"; // Original CSS for desktop
import "./PriceCalculatorMobile.css"; // New mobile-only CSS (in media queries)
import { storage } from "../core/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { addItemToCart } from "../services/cartService";
import { UserContext } from "./UserContext";
import InfoHint from "./InfoHint";
import SignInPopup from "./SignInPopup";
import { generateUUID } from "../core/utils";

const PriceCalculator = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef(null);

  // Original state variables
  const [artStyles, setArtStyles] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [additionalObjects, setAdditionalObjects] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [numPeople, setNumPeople] = useState(0);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [packaging, setPackaging] = useState("gift");
  const [gift, setGift] = useState(false);
  const [lacquer, setLacquer] = useState(false);
  const [bavovna, setBavovna] = useState(false);
  const [potal, setPotal] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadedPhotoURL, setUploadedPhotoURL] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  // Mobile-specific state
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [mobileStep, setMobileStep] = useState("style"); // 'style', 'size', 'people', 'objects', 'extras', 'upload', 'review'

  // Check for mobile view on resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Fetch data from JSON files - same as original
  useEffect(() => {
    const fetchArtStyles = async () => {
      try {
        const response = await fetch("/data/artstyles.json");
        const data = await response.json();
        setArtStyles(data.artStyles);

        const params = new URLSearchParams(location.search);
        const styleId = params.get("styleId");
        if (styleId) {
          const style = data.artStyles.find((s) => s.id === styleId);
          if (style) {
            setSelectedStyle(style);
          }
        }
      } catch (error) {
        console.error("Error fetching art styles:", error);
      }
    };

    const fetchSizes = async () => {
      try {
        const response = await fetch("/data/sizes.json");
        const data = await response.json();
        setSizes(data.sizes);
        setSelectedSize(data.sizes[0].name);
      } catch (error) {
        console.error("Error fetching sizes:", error);
      }
    };

    const fetchAdditionalObjects = async () => {
      try {
        const response = await fetch("/data/additionalObjects.json");
        const data = await response.json();
        setAdditionalObjects(data.objects);
      } catch (error) {
        console.error("Error fetching additional objects:", error);
      }
    };

    fetchArtStyles();
    fetchSizes();
    fetchAdditionalObjects();
  }, [location.search]);

  // Calculate price - unchanged from original
  const calculatePrice = () => {
    let price = 0;

    if (selectedStyle) {
      price += selectedStyle.price;
    }

    const size = sizes.find((s) => s.name === selectedSize);
    if (selectedSize) {
      if (size) {
        price += size.price;
      }
    }

    if (selectedStyle) {
      price +=
        numPeople === "Більше..."
          ? 10 * selectedStyle.personPrice
          : numPeople * selectedStyle.personPrice;
    }

    selectedObjects.forEach((object) => {
      const obj = additionalObjects.find((o) => o.name === object);
      if (obj) {
        price += obj.price;
      }
    });

    if (gift) {
      price += size?.giftPackagePrice || 0;
    }

    if (potal) {
      price += size?.patalPrice || 0;
    }

    if (bavovna) {
      price += 100;
    }

    if (lacquer) {
      price += size?.lacquerPrice || 0;
    }

    return price;
  };

  // Photo upload handler - extended to handle mobile
  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);

      const uuid = crypto.randomUUID();
      const fileExtension = file.name.split(".").pop();
      const storageRef = ref(storage, `photos/${uuid}.${fileExtension}`);

      try {
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        setUploadedPhotoURL(downloadURL);

        // For mobile UI, move to review step after upload
        if (isMobileView) {
          setMobileStep("review");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Помилка при завантаженні зображення. Спробуйте ще раз.");
      }
    }
  };

  // File input handlers - same as original
  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      handlePhotoUpload(e);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const uploadedFile = e.dataTransfer.files[0];
    if (uploadedFile) {
      handlePhotoUpload({ target: { files: [uploadedFile] } });
    }
  };

  // Clear selections - extended for mobile
  const handleClear = () => {
    setSelectedSize(sizes[0]?.name || "");
    setNumPeople(0);
    setSelectedObjects([]);
    setLacquer(false);
    setFile(null);
    setUploadedPhotoURL(null);
    setSelectedStyle(null);
    setGift(false);
    setPotal(false);
    setBavovna(false);

    // For mobile, reset to first step
    if (isMobileView) {
      setMobileStep("style");
    }
  };

  // Add to cart - same functionality, different alert for mobile
  const handleOrderClick = async () => {
    if (!user) {
      setShowPopup(true);
      if (isMobileView) {
        alert("Будь ласка, увійдіть в систему, щоб зробити замовлення");
      } else {
        alert("Please sign in to place an order then click order again.");
      }
      return;
    }

    if (user && uploadedPhotoURL && selectedSize && selectedStyle) {
      const newItem = {
        id: generateUUID(),
        render: true,
        imageURL: uploadedPhotoURL,
        selectedSize: selectedSize,
        selectedStyle: selectedStyle.name,
        numPeople: numPeople,
        additionalObjects: selectedObjects,
        packaging: packaging,
        lacquer: lacquer,
        gift: gift,
        bavovna: bavovna,
        potal: potal,
        price: calculatePrice(),
        quantity: 1,
      };

      try {
        await addItemToCart(user.uid, newItem);
        alert("Товар додано до кошика!");
        navigate("/cart");
      } catch (error) {
        console.error("Error adding item to cart:", error);
        alert("Помилка при додаванні до кошика. Спробуйте ще раз.");
      }
    } else {
      alert(
        "Будь ласка, заповніть всі поля та завантажте фото перед замовленням.",
      );
    }
  };

  const isOrderButtonActive = selectedSize && selectedStyle && uploadedPhotoURL;

  // Mobile navigation component
  const renderMobileNavigation = () => (
    <div className="mobile-steps-indicator">
      <div
        className={`step ${mobileStep === "style" ? "active" : ""} ${mobileStep !== "style" ? "completed" : ""}`}
      >
        <span>1</span>
        <p>Стиль</p>
      </div>
      <div
        className={`step ${mobileStep === "size" ? "active" : ""} ${["people", "objects", "extras", "upload", "review"].includes(mobileStep) ? "completed" : ""}`}
      >
        <span>2</span>
        <p>Розмір</p>
      </div>
      <div
        className={`step ${mobileStep === "people" ? "active" : ""} ${["objects", "extras", "upload", "review"].includes(mobileStep) ? "completed" : ""}`}
      >
        <span>3</span>
        <p>Люди</p>
      </div>
      <div
        className={`step ${mobileStep === "objects" ? "active" : ""} ${["extras", "upload", "review"].includes(mobileStep) ? "completed" : ""}`}
      >
        <span>4</span>
        <p>Об'єкти</p>
      </div>
      <div
        className={`step ${mobileStep === "extras" ? "active" : ""} ${["upload", "review"].includes(mobileStep) ? "completed" : ""}`}
      >
        <span>5</span>
        <p>Додатково</p>
      </div>
      <div
        className={`step ${mobileStep === "upload" ? "active" : ""} ${mobileStep === "review" ? "completed" : ""}`}
      >
        <span>6</span>
        <p>Фото</p>
      </div>
      <div className={`step ${mobileStep === "review" ? "active" : ""}`}>
        <span>7</span>
        <p>Огляд</p>
      </div>
    </div>
  );

  // Mobile version render
  if (isMobileView) {
    return (
      <div className="price-calculator mobile">
        {renderMobileNavigation()}

        <div className="mobile-panel-content">
          {/* Style selection step */}
          {mobileStep === "style" && (
            <div className="mobile-panel">
              <h3>Оберіть стиль</h3>
              <div className="mobile-styles-list">
                {artStyles.map((style) => (
                  <div
                    key={style.id}
                    className={`mobile-style-item ${selectedStyle?.id === style.id ? "selected" : ""}`}
                    onClick={() => setSelectedStyle(style)}
                  >
                    <div className="style-name">{style.name}</div>
                    <div className="style-price">{style.price} UAH</div>
                  </div>
                ))}
              </div>

              <div className="mobile-step-navigation">
                <button
                  onClick={() => setMobileStep("size")}
                  className="mobile-next-button"
                  disabled={!selectedStyle}
                >
                  Далі
                </button>
              </div>
            </div>
          )}

          {/* Size selection step */}
          {mobileStep === "size" && (
            <div className="mobile-panel">
              <h3>Оберіть розмір</h3>
              <div className="mobile-sizes-grid">
                {sizes.map((size) => (
                  <div
                    key={size.name}
                    className={`mobile-size-item ${selectedSize === size.name ? "selected" : ""}`}
                    onClick={() => setSelectedSize(size.name)}
                  >
                    {size.name}
                  </div>
                ))}
              </div>

              <div className="mobile-step-navigation">
                <button
                  onClick={() => setMobileStep("style")}
                  className="mobile-back-button"
                >
                  Назад
                </button>
                <button
                  onClick={() => setMobileStep("people")}
                  className="mobile-next-button"
                  disabled={!selectedSize}
                >
                  Далі
                </button>
              </div>
            </div>
          )}

          {/* Number of people step */}
          {mobileStep === "people" && (
            <div className="mobile-panel">
              <h3>
                Кількість людей на фото
                <InfoHint hintText="Щобільше людей на фото – тим більше промальовки від художника вимагається, відповідно і ціна змінюватиметься згідно складності фото для картини." />
              </h3>

              <div className="mobile-people-options">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, "Більше..."].map((option) => (
                  <div
                    key={option}
                    className={`mobile-people-option ${numPeople === option ? "selected" : ""}`}
                    onClick={() => setNumPeople(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>

              <div className="mobile-step-navigation">
                <button
                  onClick={() => setMobileStep("size")}
                  className="mobile-back-button"
                >
                  Назад
                </button>
                <button
                  onClick={() => setMobileStep("objects")}
                  className="mobile-next-button"
                >
                  Далі
                </button>
              </div>
            </div>
          )}

          {/* Additional objects step */}
          {mobileStep === "objects" && (
            <div className="mobile-panel">
              <h3>
                Додаткові об'єкти на фото
                <InfoHint hintText="Чим більше об'єктів на фото – тим більша деталізації та уважність від художника вимагається, аби кожен елемент на картині гармонійно виглядав" />
              </h3>

              <div className="mobile-objects-list">
                {additionalObjects.map((object) => (
                  <div
                    key={object.name}
                    className={`mobile-object-item ${selectedObjects.includes(object.name) ? "selected" : ""}`}
                    onClick={() => {
                      setSelectedObjects((prev) =>
                        prev.includes(object.name)
                          ? prev.filter((o) => o !== object.name)
                          : [...prev, object.name],
                      );
                    }}
                  >
                    <div className="object-name">
                      {object.name}
                      {object.hint && <InfoHint hintText={object.hint} />}
                    </div>
                  </div>
                ))}
              </div>

              <div className="mobile-step-navigation">
                <button
                  onClick={() => setMobileStep("people")}
                  className="mobile-back-button"
                >
                  Назад
                </button>
                <button
                  onClick={() => setMobileStep("extras")}
                  className="mobile-next-button"
                >
                  Далі
                </button>
              </div>
            </div>
          )}

          {/* Extras step */}
          {mobileStep === "extras" && (
            <div className="mobile-panel">
              <h3>
                Додаткові опції
                <InfoHint hintText="Замовляєте картину на подарунок, або ж бажаєте урізноманітнити його текстурним лаком на більш високої якості полотні? Не проблема – просто натикніть нам проставивши галочку напроти відповідного пунку." />
              </h3>

              <div className="mobile-extras-list">
                <div
                  className={`mobile-extra-item ${gift ? "selected" : ""}`}
                  onClick={() => setGift(!gift)}
                >
                  <div className="extra-name">Подарункове пакування</div>
                </div>

                <div
                  className={`mobile-extra-item ${lacquer ? "selected" : ""}`}
                  onClick={() => setLacquer(!lacquer)}
                >
                  <div className="extra-name">
                    Нанесення текстурного лаку
                    <InfoHint hintText="3D текстурний лак має не тільки захисну, а ще й естетичну функцію. Наноситься нашими художниками вручну пензлем, як прозора текстурна паста для отримання об'ємної поверхні з ефектом масляних мазків на полотні." />
                  </div>
                </div>

                <div
                  className={`mobile-extra-item ${bavovna ? "selected" : ""}`}
                  onClick={() => setBavovna(!bavovna)}
                >
                  <div className="extra-name">Натуральне полотно (бавовна)</div>
                </div>

                <div
                  className={`mobile-extra-item ${potal ? "selected" : ""}`}
                  onClick={() => setPotal(!potal)}
                >
                  <div className="extra-name">
                    Нанесення поталі
                    <InfoHint hintText="Надайте своїм картинам cяючого золотистого, мідного або сріблястий відтінку за допомогою нанесення поталі безпосередньо на полотно. Це робиться вручну художником,що надає вашій картині індивідуальності та розкішний вигляд." />
                  </div>
                </div>
              </div>

              <div className="mobile-step-navigation">
                <button
                  onClick={() => setMobileStep("objects")}
                  className="mobile-back-button"
                >
                  Назад
                </button>
                <button
                  onClick={() => setMobileStep("upload")}
                  className="mobile-next-button"
                >
                  Далі
                </button>
              </div>
            </div>
          )}

          {/* Photo upload step */}
          {mobileStep === "upload" && (
            <div className="mobile-panel">
              <h3>Завантажте своє фото</h3>

              <div
                className="mobile-upload-area"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="mobile-file-upload"
                  accept="image/*"
                  capture="environment"
                />
                <label
                  htmlFor="mobile-file-upload"
                  className="mobile-upload-button"
                >
                  {file ? "Змінити фото" : "Вибрати фото"}
                </label>

                {uploadedPhotoURL && (
                  <div className="mobile-photo-preview">
                    <img src={uploadedPhotoURL} alt="Завантажене фото" />
                  </div>
                )}
              </div>

              <div className="mobile-step-navigation">
                <button
                  onClick={() => setMobileStep("extras")}
                  className="mobile-back-button"
                >
                  Назад
                </button>
                <button
                  onClick={() => setMobileStep("review")}
                  className="mobile-next-button"
                  disabled={!uploadedPhotoURL}
                >
                  Далі
                </button>
              </div>
            </div>
          )}

          {/* Review step */}
          {mobileStep === "review" && (
            <div className="mobile-panel">
              <h3>Перевірте замовлення</h3>

              {uploadedPhotoURL && (
                <div className="mobile-photo-preview">
                  <img
                    src={uploadedPhotoURL}
                    alt="Завантажене фото"
                    className="mobile-review-image"
                  />
                </div>
              )}

              <div className="mobile-order-details">
                <div className="mobile-order-detail">
                  <span className="detail-label">Стиль:</span>
                  <span className="detail-value">
                    {selectedStyle?.name || "Не обрано"}
                  </span>
                </div>

                <div className="mobile-order-detail">
                  <span className="detail-label">Розмір:</span>
                  <span className="detail-value">
                    {selectedSize || "Не обрано"}
                  </span>
                </div>

                <div className="mobile-order-detail">
                  <span className="detail-label">Кількість людей:</span>
                  <span className="detail-value">{numPeople || "0"}</span>
                </div>

                <div className="mobile-order-detail">
                  <span className="detail-label">Додаткові об'єкти:</span>
                  <span className="detail-value">
                    {selectedObjects.length > 0
                      ? selectedObjects.join(", ")
                      : "Немає"}
                  </span>
                </div>

                <div className="mobile-order-detail">
                  <span className="detail-label">Додаткові опції:</span>
                  <span className="detail-value">
                    {[
                      gift ? "Подарункове пакування" : null,
                      lacquer ? "Текстурний лак" : null,
                      bavovna ? "Натуральне полотно" : null,
                      potal ? "Нанесення поталі" : null,
                    ]
                      .filter(Boolean)
                      .join(", ") || "Немає"}
                  </span>
                </div>

                <div className="mobile-order-price">
                  <span className="price-label">Ціна:</span>
                  <span className="price-value">{calculatePrice()} UAH*</span>
                </div>

                <div className="price-disclaimer">
                  * Ціна є орієнтовною та буде остаточно погоджена після дзвінка
                  нашого менеджера
                  <br />
                  ** Ціна не включає вартість доставки
                </div>
              </div>

              <div className="mobile-step-actions">
                <button
                  onClick={() => setMobileStep("upload")}
                  className="mobile-back-button"
                >
                  Назад
                </button>
                <button onClick={handleClear} className="mobile-clear-button">
                  Очистити
                </button>
                <button
                  onClick={handleOrderClick}
                  className="mobile-order-button"
                  disabled={!isOrderButtonActive}
                >
                  Замовити
                </button>
              </div>

              {!isOrderButtonActive && (
                <p className="mobile-warning">
                  Будь ласка, заповніть всі поля та завантажте фото перед
                  замовленням.
                </p>
              )}

              {showPopup && (
                <SignInPopup closePopup={() => setShowPopup(false)} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="price-calculator">
      <div className="calculator-top">
        <div className="columns-container">
          {/* Style Selection */}
          <div className="column">
            <h4>Стиль</h4>
            {artStyles.map((style) => (
              <label key={style.id} className="radio-label">
                <input
                  type="radio"
                  name="style"
                  value={style.id}
                  checked={selectedStyle?.id === style.id}
                  onChange={() => setSelectedStyle(style)}
                />
                {style.name}
              </label>
            ))}
          </div>

          {/* Size Section */}
          <div className="column column-size">
            <h4>Розмір</h4>
            {sizes.map((size) => (
              <label key={size.name} className="radio-label">
                <input
                  type="radio"
                  name="size"
                  value={size.name}
                  checked={selectedSize === size.name}
                  onChange={() => setSelectedSize(size.name)}
                />
                {size.name}
              </label>
            ))}
          </div>

          {/* Number of People Section */}
          <div className="column">
            <h4>
              Кількість людей на фото
              <InfoHint hintText="Щобільше людей на фото – тим більше промальовки від художника вимагається, відповідно і ціна змінюватиметься згідно складності фото для картини." />
            </h4>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, "Більше..."].map((option) => (
              <label key={option} className="checkbox-label">
                <input
                  type="checkbox"
                  checked={numPeople === option}
                  onChange={() => setNumPeople(option)}
                />
                {option}
              </label>
            ))}
          </div>

          {/* Additional Objects Section */}
          <div className="column">
            <h4>
              Додаткові об’єкти на фото{" "}
              <InfoHint hintText="Чим більше об’єктів на фото – тим більша деталізації та уважність від художника вимагається, аби кожен елемент на картині гармонійно виглядав" />
            </h4>
            {additionalObjects.map((object) => (
              <label key={object.name} className="checkbox-label">
                <input
                  type="checkbox"
                  checked={selectedObjects.includes(object.name)}
                  onChange={() => {
                    setSelectedObjects((prev) =>
                      prev.includes(object.name)
                        ? prev.filter((o) => o !== object.name)
                        : [...prev, object.name],
                    );
                  }}
                />
                {object.name}
                <span className="additional-info">
                  {object && object.hint && <InfoHint hintText={object.hint} />}
                </span>
              </label>
            ))}
          </div>

          {/* Packaging Section */}
          <div className="column">
            <h4>
              Додаткові
              <InfoHint hintText="Замовляєте картину на подарунок, або ж бажаєте урізноманітнити його текстурним лаком на більш високої якості полотні? Не проблема – просто натякніть нам проставивши галочку напроти відповідного пунку." />
            </h4>
            <label className="radio-label">
              <input
                type="checkbox"
                name="gift"
                value="gift"
                checked={gift}
                onChange={() => setGift(!gift)}
              />
              Подарункове пакування
            </label>
            <label className="radio-label">
              <input
                type="checkbox"
                name="packaging"
                checked={lacquer}
                value="transport"
                onChange={() => setLacquer(!lacquer)}
              />
              Нанесення текстурного лаку
              <InfoHint hintText="3D текстурний лак має не тільки захисну, а ще й естетичну функцію. Наноситься нашими художниками вручну пензлем, як прозора текстурна паста для отримання об'ємної поверхні з ефектом масляних мазків на полотні." />
            </label>
            <label className="radio-label">
              <input
                type="checkbox"
                name="packaging"
                checked={bavovna}
                value="transport"
                onChange={() => setBavovna(!bavovna)}
              />
              Натуральне полотно (бавовна)
            </label>
            <label className="radio-label">
              <input
                type="checkbox"
                name="packaging"
                value="transport"
                checked={potal}
                onChange={() => setPotal(!potal)}
              />
              Нанесення поталі
              <InfoHint hintText="Надайте своїм картинам cяючого золотистого, мідного або сріблястий відтінку за допомогою нанесення поталі безпосередньо на полотно. Це робиться вручну художником,що надає вашій картині індивідуальності та розкішний вигляд." />
            </label>
          </div>
        </div>
      </div>

      {/* Bottom Part with Upload and Price */}
      <div className="calculator-bottom">
        {/* Left Column: File Upload */}
        <div className="upload-column">
          <div
            className="upload-area"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-upload"
            />
            <label htmlFor="file-upload" className="upload-label">
              {file ? file.name : "Тицніть або перетягніть сюди фото"}
            </label>
          </div>

          {/* Photo Preview */}
          {uploadedPhotoURL && (
            <div className="photo-preview">
              <img src={uploadedPhotoURL} alt="Uploaded Preview" />
            </div>
          )}
        </div>

        {/* Right Column: Price and Actions */}
        <div className="price-column">
          <div className="price-section">
            <h3>Ціна: {calculatePrice()} грн*</h3>
            <button onClick={handleClear} className="clear-button">
              Очистити
            </button>
            <button
              onClick={handleOrderClick}
              className="order-button"
              disabled={!isOrderButtonActive}
            >
              Замовити
            </button>
            {showPopup && (
              <SignInPopup closePopup={() => setShowPopup(false)} />
            )}
            {!isOrderButtonActive && (
              <p className="roomlayout-hint">
                Будь ласка, заповніть всі поля та завантажте фото.
              </p>
            )}

            <div className="price-disclaimer">
              * Ціна є орієнтовною та буде остаточно погоджена після дзвінка
              нашого менеджера
              <br />
              ** Ціна не включає вартість доставки
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceCalculator;
