import React from "react";
import "./Discounts.css";

const Discounts = () => {
  const currentDate = new Date();

  // Sample promotions with expiration dates
  const promotions = [
    {
      id: 1,
      title: "Весняна знижка -15%",
      description:
        "Знижка 15% на всі замовлення при використанні промокоду SPRING2025",
      code: "SPRING2025",
      expires: new Date(2025, 4, 31), // May 31, 2025
      imageUrl: "/img/promos/spring-promo.jpg",
      isNew: true,
    },
    {
      id: 2,
      title: "День народження -20%",
      description:
        "Отримайте знижку 20% на замовлення в місяць вашого дня народження. Для отримання знижки напишіть нам у месенджер з підтвердженням дати народження.",
      expires: null, // Never expires
      imageUrl: "/img/promos/birthday-promo.jpg",
      isNew: false,
    },
    {
      id: 3,
      title: "3+1 на картини формату 20x30",
      description:
        "При замовленні 3 картин формату 20x30 см, четверту ви отримуєте безкоштовно! Просто додайте 4 картини в кошик і знижка буде застосована автоматично.",
      expires: new Date(2025, 3, 15), // April 15, 2025
      imageUrl: "/img/promos/bundle-promo.jpg",
      isNew: true,
    },
    {
      id: 4,
      title: "Знижка 10% на перше замовлення",
      description:
        "Для нових клієнтів - знижка 10% на перше замовлення при реєстрації на сайті та підписці на наші соціальні мережі.",
      code: "FIRSTORDER",
      expires: null, // Never expires
      imageUrl: "/img/promos/first-order-promo.jpg",
      isNew: false,
    },
    {
      id: 5,
      title: "Безкоштовна доставка",
      description:
        "Безкоштовна доставка Новою Поштою при замовленні від 2000 грн.",
      expires: null, // Never expires
      imageUrl: "/img/promos/free-shipping.jpg",
      isNew: false,
    },
  ];

  // Check if promotion is active (not expired)
  const isActive = (expiryDate) => {
    if (!expiryDate) return true; // No expiry date means it never expires
    return currentDate <= expiryDate;
  };

  // Filter active promotions
  const activePromotions = promotions.filter((promo) =>
    isActive(promo.expires),
  );

  return (
    <div className="discounts-container">
      <div className="discounts-header">
        <img
          src="/img/Logo_girl_trans.png"
          alt="ArtMe Holst Logo"
          className="discounts-logo"
        />
        <h1>Акції та знижки</h1>
        <p className="discounts-subtitle">
          Спеціальні пропозиції та знижки для наших клієнтів
        </p>
      </div>

      <div className="active-promotions">
        <h2>Діючі акції</h2>
        <div className="promotions-grid">
          {activePromotions.map((promo) => (
            <div
              key={promo.id}
              className={`promotion-card ${promo.isNew ? "new-promotion" : ""}`}
            >
              {promo.isNew && <span className="new-badge">НОВА</span>}
              <div className="promotion-image">
                <img
                  src={promo.imageUrl || "/img/promos/default-promo.jpg"}
                  alt={promo.title}
                />
              </div>
              <div className="promotion-content">
                <h3>{promo.title}</h3>
                <p>{promo.description}</p>
                {promo.code && (
                  <div className="promo-code">
                    <span>Промокод:</span>
                    <code>{promo.code}</code>
                  </div>
                )}
                {promo.expires && (
                  <p className="expiry-date">
                    Діє до: {promo.expires.toLocaleDateString("uk-UA")}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="loyalty-program">
        <h2>Програма лояльності</h2>
        <div className="loyalty-content">
          <div className="loyalty-info">
            <p>
              В ArtMe Holst ми цінуємо наших постійних клієнтів та пропонуємо
              прогресивну систему знижок:
            </p>
            <ul>
              <li>
                <strong>5% знижки</strong> - після 2-го замовлення
              </li>
              <li>
                <strong>7% знижки</strong> - після 5-го замовлення
              </li>
              <li>
                <strong>10% знижки</strong> - після 10-го замовлення
              </li>
              <li>
                <strong>15% знижки</strong> - VIP-клієнт (після 15-го
                замовлення)
              </li>
            </ul>
            <p>
              Знижки накопичуються автоматично та застосовуються до всіх
              наступних замовлень.
            </p>
          </div>
        </div>
      </div>

      <div className="bulk-discounts">
        <h2>Знижки на гуртові замовлення</h2>
        <p>
          Плануєте корпоративне замовлення або хочете замовити кілька картин
          одразу? Ми пропонуємо спеціальні знижки для гуртових замовлень:
        </p>

        <div className="discount-table">
          <div className="table-row header">
            <div className="table-cell">Кількість</div>
            <div className="table-cell">Знижка</div>
          </div>
          <div className="table-row">
            <div className="table-cell">3-5 картин</div>
            <div className="table-cell">5%</div>
          </div>
          <div className="table-row">
            <div className="table-cell">6-10 картин</div>
            <div className="table-cell">10%</div>
          </div>
          <div className="table-row">
            <div className="table-cell">11-20 картин</div>
            <div className="table-cell">15%</div>
          </div>
          <div className="table-row">
            <div className="table-cell">Більше 20 картин</div>
            <div className="table-cell">20%</div>
          </div>
        </div>

        <p className="bulk-note">
          Для отримання гуртової знижки, будь ласка, зв'яжіться з нами за
          телефоном або через форму зворотного зв'язку.
        </p>
      </div>

      <div className="special-offers">
        <h2>Спеціальні пропозиції</h2>
        <div className="offers-grid">
          <div className="offer-card">
            <h3>Замовлення для Instagram</h3>
            <p>
              Розмістіть фото вашої картини в Instagram з відміткою @artme_holst
              та отримайте знижку 5% на наступне замовлення!
            </p>
          </div>
          <div className="offer-card">
            <h3>Подарункові сертифікати</h3>
            <p>
              Подаруйте близьким можливість створити унікальну картину.
              Подарункові сертифікати доступні номіналом від 500 грн.
            </p>
          </div>
          <div className="offer-card">
            <h3>Сезонні знижки</h3>
            <p>
              Слідкуйте за нашими соціальними мережами, щоб дізнаватись про
              сезонні акції та спеціальні пропозиції!
            </p>
          </div>
        </div>
      </div>

      <div className="discounts-footer">
        <h3>Умови та правила</h3>
        <ul>
          <li>
            Знижки та акції не сумуються, окрім знижок за програмою лояльності.
          </li>
          <li>
            Знижка застосовується до вартості товару без урахування доставки.
          </li>
          <li>
            Ми залишаємо за собою право змінювати умови акцій без попереднього
            повідомлення.
          </li>
          <li>
            Для отримання додаткової інформації зв'яжіться з нами за контактними
            телефонами.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Discounts;
