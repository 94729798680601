import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./LegalPage.css";

const LegalPage = () => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState("terms");

  useEffect(() => {
    // Get the section from the URL hash
    const hash = location.hash.replace("#", "");
    if (hash && document.getElementById(hash)) {
      setActiveSection(hash);

      // Scroll to the section after a small delay to ensure rendering
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  }, [location]);

  // Handle navigation between sections
  const navigateToSection = (sectionId) => {
    setActiveSection(sectionId);
    window.location.hash = sectionId;
  };

  return (
    <div className="legal-page-container">
      <div className="legal-sidebar">
        <h2>Правова інформація</h2>
        <ul className="legal-navigation">
          <li className={activeSection === "terms" ? "active" : ""}>
            <a href="#terms" onClick={() => navigateToSection("terms")}>
              Угода користувача
            </a>
          </li>
          <li className={activeSection === "returns" ? "active" : ""}>
            <a href="#returns" onClick={() => navigateToSection("returns")}>
              Обмін - повернення товару
            </a>
          </li>
          <li className={activeSection === "copyright" ? "active" : ""}>
            <a href="#copyright" onClick={() => navigateToSection("copyright")}>
              Авторське право
            </a>
          </li>
          <li className={activeSection === "offer" ? "active" : ""}>
            <a href="#offer" onClick={() => navigateToSection("offer")}>
              Договір публічної оферти
            </a>
          </li>
          <li className={activeSection === "privacy" ? "active" : ""}>
            <a href="#privacy" onClick={() => navigateToSection("privacy")}>
              Персональні дані
            </a>
          </li>
          <li className={activeSection === "cookies" ? "active" : ""}>
            <a href="#cookies" onClick={() => navigateToSection("cookies")}>
              Політика Cookies
            </a>
          </li>
        </ul>
      </div>

      <div className="legal-content">
        <section
          id="terms"
          className={activeSection === "terms" ? "active" : ""}
        >
          <h2>Угода користувача</h2>
          <p>Ласкаво просимо до ArtMe Holst!</p>
          <p>
            Ця угода користувача ("Угода") регулює взаємини між ArtMe Holst
            ("ми", "наш", "нас") та користувачами нашого веб-сайту ("Ви", "Ваш",
            "Вас").
          </p>
          <h3>1. Прийняття умов</h3>
          <p>
            Використовуючи наш веб-сайт, Ви погоджуєтеся дотримуватися цієї
            Угоди та інших політик, які ми можемо опублікувати. Якщо Ви не
            погоджуєтеся з умовами, будь ласка, не використовуйте наш веб-сайт.
          </p>
          <h3>2. Зміни в угоді</h3>
          <p>
            Ми залишаємо за собою право змінювати цю Угоду в будь-який час.
            Зміни набувають чинності з моменту публікації на сайті. Ваше
            продовження використання сайту після внесення змін означає прийняття
            нових умов.
          </p>
          <h3>3. Аккаунт користувача</h3>
          <p>
            Для використання певних функцій нашого сайту Ви повинні створити
            аккаунт. Ви несете відповідальність за безпеку вашого аккаунту та
            всі дії, що відбуваються під вашим аккаунтом.
          </p>
          <h3>4. Зобов'язання користувача</h3>
          <p>
            Ви зобов'язуєтесь використовувати наш сайт у відповідності до
            законодавства України та не порушувати права третіх осіб.
          </p>
        </section>

        <section
          id="returns"
          className={activeSection === "returns" ? "active" : ""}
        >
          <h2>Обмін - повернення товару</h2>
          <p>
            Відповідно до Закону України "Про захист прав споживачів", ArtMe
            Holst має чітку політику щодо повернення та обміну товарів.
          </p>
          <h3>1. Повернення якісного товару</h3>
          <p>
            Ви маєте право повернути якісний товар протягом 14 днів з моменту
            отримання, якщо:
          </p>
          <ul>
            <li>
              Товар не був у використанні і збережено його товарний вигляд
            </li>
            <li>Збережені споживчі властивості, пломби, ярлики</li>
            <li>Є документ, що підтверджує факт покупки (чек, квитанція)</li>
          </ul>
          <h3>2. Повернення неякісного товару</h3>
          <p>Якщо Ви виявили дефект або пошкодження товару, Ви можете:</p>
          <ul>
            <li>Замінити товар на аналогічний</li>
            <li>Замінити товар на інший з відповідним перерахунком вартості</li>
            <li>Отримати повне відшкодування вартості товару</li>
          </ul>
          <h3>3. Винятки з політики повернення</h3>
          <p>
            Згідно з законодавством, деякі товари не підлягають поверненню або
            обміну, зокрема, товари виготовлені на замовлення. Картини,
            виготовлені за індивідуальним замовленням з використанням ваших
            фотографій, не підлягають поверненню, якщо вони відповідають
            замовленим параметрам.
          </p>
        </section>

        <section
          id="copyright"
          className={activeSection === "copyright" ? "active" : ""}
        >
          <h2>Авторське право</h2>
          <p>
            ArtMe Holst поважає права інтелектуальної власності і вимагає цього
            ж від наших користувачів.
          </p>
          <h3>1. Зміст сайту</h3>
          <p>
            Весь контент, що міститься на нашому сайті, включаючи тексти,
            графіку, логотипи, зображення, аудіо та відео, є власністю ArtMe
            Holst або наших ліцензіарів і захищений законодавством про авторське
            право.
          </p>
          <h3>2. Використання контенту</h3>
          <p>Без отримання попередньої письмової згоди, Ви не маєте права:</p>
          <ul>
            <li>
              Копіювати, відтворювати або використовувати контент сайту в
              комерційних цілях
            </li>
            <li>
              Модифікувати, створювати похідні роботи на основі контенту сайту
            </li>
            <li>
              Використовувати контент сайту на інших сайтах або в мережевому
              середовищі
            </li>
          </ul>
          <h3>3. Користувацький контент</h3>
          <p>
            Завантажуючи фотографії для створення картин, Ви гарантуєте, що
            маєте всі необхідні права на ці фотографії. ArtMe Holst не несе
            відповідальності за порушення авторських прав, пов'язаних з
            матеріалами, наданими користувачами.
          </p>
        </section>

        <section
          id="offer"
          className={activeSection === "offer" ? "active" : ""}
        >
          <h2>Договір публічної оферти</h2>
          <p>
            Цей договір є офіційною пропозицією (публічною офертою) ArtMe Holst
            укласти договір купівлі-продажу товарів, представлених на нашому
            сайті.
          </p>
          <h3>1. Предмет договору</h3>
          <p>
            Продавець зобов'язується передати у власність Покупця товари, а
            Покупець зобов'язується прийняти і оплатити ці товари на умовах
            цього Договору.
          </p>
          <h3>2. Момент укладення договору</h3>
          <p>
            Договір вважається укладеним з моменту оформлення замовлення на
            сайті та його підтвердження ArtMe Holst.
          </p>
          <h3>3. Ціна товару</h3>
          <p>
            Ціна на кожний товар вказана на сайті. У випадку зміни ціни,
            Продавець зобов'язаний повідомити Покупця про це до моменту
            підтвердження замовлення.
          </p>
          <h3>4. Порядок розрахунків</h3>
          <p>
            Оплата здійснюється у гривнях відповідно до запропонованих на сайті
            способів оплати.
          </p>
        </section>

        <section
          id="privacy"
          className={activeSection === "privacy" ? "active" : ""}
        >
          <h2>Персональні дані</h2>
          <p>
            ArtMe Holst зобов'язується захищати Ваші персональні дані відповідно
            до Закону України "Про захист персональних даних".
          </p>
          <h3>1. Збір персональних даних</h3>
          <p>Ми збираємо наступні персональні дані:</p>
          <ul>
            <li>Ім'я та прізвище</li>
            <li>Контактна інформація (електронна пошта, телефон)</li>
            <li>Адреса доставки</li>
            <li>Інформація про платежі</li>
          </ul>
          <h3>2. Мета обробки персональних даних</h3>
          <p>Ваші персональні дані обробляються для:</p>
          <ul>
            <li>Обробки та виконання замовлень</li>
            <li>Надання клієнтської підтримки</li>
            <li>Покращення роботи нашого сайту</li>
            <li>Комунікації щодо спеціальних пропозицій та акцій</li>
          </ul>
          <h3>3. Передача персональних даних</h3>
          <p>
            Ми не продаємо, не обмінюємо і не передаємо Ваші персональні дані
            третім особам без Вашої згоди, за винятком випадків, коли це
            необхідно для виконання наших зобов'язань (наприклад, служби
            доставки).
          </p>
        </section>

        <section
          id="cookies"
          className={activeSection === "cookies" ? "active" : ""}
        >
          <h2>Політика Cookies</h2>
          <p>
            ArtMe Holst використовує файли cookies для покращення вашого досвіду
            використання сайту.
          </p>
          <h3>1. Що таке cookies?</h3>
          <p>
            Cookies — це невеликі текстові файли, які зберігаються на вашому
            пристрої при відвідуванні сайту. Вони допомагають сайту запам'ятати
            інформацію про вас, наприклад, вашу мову та налаштування.
          </p>
          <h3>2. Типи cookies, які ми використовуємо</h3>
          <ul>
            <li>
              <strong>Необхідні cookies:</strong> Потрібні для функціонування
              сайту
            </li>
            <li>
              <strong>Функціональні cookies:</strong> Допомагають запам'ятати
              ваші налаштування
            </li>
            <li>
              <strong>Аналітичні cookies:</strong> Допомагають зрозуміти, як
              відвідувачі використовують сайт
            </li>
          </ul>
          <h3>3. Управління cookies</h3>
          <p>
            Ви можете контролювати і/або видаляти cookies за бажанням.
            Детальніше про видалення cookies можна дізнатися на сайті
            aboutcookies.org. Ви можете видалити всі файли cookies, які вже є на
            вашому комп'ютері, а також налаштувати більшість браузерів на
            блокування їх розміщення.
          </p>
        </section>
      </div>
    </div>
  );
};

export default LegalPage;
