import React from "react";
import "./FooterInfo.css";

const FooterInfo = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-info-container">
      <div className="footer-logo-section">
        <img
          src="/img/Logo_girl_trans.png"
          alt="ArtMe Holst Logo"
          className="footer-logo"
        />
        <p className="footer-paragraph">
          ArtMe Holst – виробник повного циклу картин на полотні: від ідеї в
          Вашій голові до картини на Вашій стіні
        </p>
        <div className="footer-social-links">
          <a href="https://t.me/ARTME_holst">
            <img src="/img/icons/tg.png" alt="Telegram" />
          </a>
          <a href="#">
            <img src="/img/icons/vber.png" alt="Viber" />
          </a>
          <a href="https://instagram.com/artme_holst">
            <img src="/img/icons/ig.png" alt="Instagram" />
          </a>
          <a href="#">
            <img src="/img/icons/tt.png" alt="TikTok" />
          </a>
        </div>
        <p className="footer-paragraph">
          artmeholst.com | {currentYear} | Build: #000047
        </p>
      </div>
      <div className="footer-links-section">
        <div className="footer-links-column">
          <h4>Інформація:</h4>
          <ul>
            <li>
              <a href="/">Головна</a>
            </li>
            <li>
              <a href="/about">Про нас</a>
            </li>
            <li>
              <a href="/contact">Контакти</a>
            </li>
            <li>
              <a href="/delivery">Доставка та оплата</a>
            </li>
            <li>
              <a href="/discounts">Знижки та акції</a>
            </li>
            <li>
              <a href="/faq">FAQ</a>
            </li>
          </ul>
        </div>
        <div className="footer-links-column">
          <h4>Категорії:</h4>
          <ul>
            <li>
              <a href="/styles?styleId=shain-art">Шайн арт</a>
            </li>
            <li>
              <a href="/styles?styleId=ilustratsiya-na-foto">
                Ілюстрація на фото
              </a>
            </li>
            <li>
              <a href="/styles?styleId=zhyvopys">Живопис</a>
            </li>
            <li>
              <a href="/styles?styleId=istorichniy">Історичні</a>
            </li>
            <li>
              <a href="/styles?styleId=zamina-fonu">Заміна фону</a>
            </li>
            <li>
              <a href="/styles?styleId=kolazh">Колажі</a>
            </li>
            <li>
              <a href="/styles?styleId=multyashni">Мультяшні</a>
            </li>
            <li>
              <a href="/styles?styleId=realizm">Реалізм</a>
            </li>
            <li>
              <a href="/styles?styleId=a-takozh">А також...</a>
            </li>
            <li>
              <a href="/styles?styleId=puhnastiki">Пухнастики</a>
            </li>
          </ul>
        </div>
        <div className="footer-links-column">
          <h4>Правова інформація:</h4>
          <ul>
            <li>
              <a href="/legal#terms">Угода користувача</a>
            </li>
            <li>
              <a href="/legal#returns">Обмін - повернення товару</a>
            </li>
            <li>
              <a href="/legal#copyright">Авторське право</a>
            </li>
            <li>
              <a href="/legal#offer">Договір публічної оферти</a>
            </li>
            <li>
              <a href="/legal#privacy">Персональні дані</a>
            </li>
            <li>
              <a href="/legal#cookies">Політика Cookies</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterInfo;
