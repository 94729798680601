import React, { useContext, useState, useEffect, useRef } from "react";
import "./RoomLayout.css"; // Make sure to update this CSS file with responsive styles
import "./RoomLayoutMobile.css";
import { UserContext } from "./UserContext";
import { addItemToCart } from "../services/cartService";
import { storage } from "../core/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import RoomWithUserPhoto from "./RoomWithUserPhoto"; // The original component

// Import your ORIGINAL RoomWithUserPhoto component for desktop
// Leave this as-is to keep desktop layout exactly the same
import OriginalRoomWithUserPhoto from "./OriginalRoomWithUserPhoto";
import { generateUUID } from "../core/utils";

const RoomLayout = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [sizes, setSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [uploadedPhotoURL, setUploadedPhotoURL] = useState(null);
  const [isOrderButtonActive, setIsOrderButtonActive] = useState(false);
  const fileInputRef = useRef(null);

  // State for mobile responsiveness
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [mobileStep, setMobileStep] = useState("size"); // 'size', 'upload', 'preview'
  const containerRef = useRef(null);

  // Fetch sizes from the server
  useEffect(() => {
    const fetchSizes = async () => {
      try {
        const response = await fetch("/data/roomLayoutData.json");
        const data = await response.json();
        setSizes(data);
      } catch (error) {
        console.error("Error fetching sizes:", error);
      }
    };

    fetchSizes();
  }, []);

  // Check for mobile view on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (selectedSize && uploadedPhotoURL) {
      setIsOrderButtonActive(true);
    } else {
      setIsOrderButtonActive(false);
    }
  }, [selectedSize, uploadedPhotoURL]);

  const handleSizeSelect = (size) => {
    setSelectedSize(size);

    // For mobile flow
    if (isMobileView) {
      setMobileStep("upload");
    } else {
      // Scroll to the preview section on desktop - KEEP ORIGINAL BEHAVIOR
      const element = document.getElementById("target-section");
      if (element) {
        setTimeout(() => element.scrollIntoView({ behavior: "smooth" }), 10);
      }
    }
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const uuid = generateUUID();
      const fileExtension = file.name.split(".").pop();
      const storageRef = ref(storage, `photos/${uuid}.${fileExtension}`);

      try {
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        setUploadedPhotoURL(downloadURL);

        // For mobile flow
        if (isMobileView) {
          setMobileStep("preview");
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Помилка при завантаженні зображення. Спробуйте ще раз.");
      }
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleAddToCart = async () => {
    if (!user) {
      alert("Будь ласка, увійдіть в систему, щоб зробити замовлення.");
      return;
    }

    if (user && uploadedPhotoURL && selectedSize) {
      const newItem = {
        id: crypto.randomUUID(),
        render: false,
        imageURL: uploadedPhotoURL,
        selectedSize: selectedSize.name,
        quantity: 1,
        price: selectedSize.price,
      };

      try {
        await addItemToCart(user.uid, newItem);
        alert("Товар додано до кошика!");
        navigate("/cart");
      } catch (error) {
        console.error("Error adding item to cart:", error);
        alert("Помилка при додаванні до кошика. Спробуйте ще раз.");
      }
    } else {
      alert("Будь ласка, виберіть розмір і завантажте фото перед замовленням.");
    }
  };

  // Mobile version render
  if (isMobileView) {
    return (
      <div className="roomlayout-container mobile" ref={containerRef}>
        {/* Mobile step indicator */}
        <div className="mobile-steps-indicator">
          <div
            className={`step ${mobileStep === "size" ? "active" : ""} ${mobileStep === "upload" || mobileStep === "preview" ? "completed" : ""}`}
          >
            <span>1</span>
            <p>Розмір</p>
          </div>
          <div
            className={`step ${mobileStep === "upload" ? "active" : ""} ${mobileStep === "preview" ? "completed" : ""}`}
          >
            <span>2</span>
            <p>Фото</p>
          </div>
          <div className={`step ${mobileStep === "preview" ? "active" : ""}`}>
            <span>3</span>
            <p>Перегляд</p>
          </div>
        </div>

        {/* Size selection step */}
        {mobileStep === "size" && (
          <div className="mobile-step-content">
            <h3>Оберіть розмір для вашої картини</h3>
            <div className="mobile-sizes-grid">
              {sizes.map((size) => (
                <button
                  key={size.name}
                  className={`mobile-size-button ${selectedSize?.name === size.name ? "selected" : ""}`}
                  onClick={() => handleSizeSelect(size)}
                >
                  <div className="size-name">{size.name}</div>
                  <div className="size-price">{size.price} UAH</div>
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Photo upload step */}
        {mobileStep === "upload" && (
          <div className="mobile-step-content">
            <h3>Завантажте своє фото</h3>
            <div className="mobile-upload-area">
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handlePhotoUpload}
                accept="image/*"
                capture="environment"
              />
              <button
                onClick={handleUploadClick}
                className="mobile-upload-button"
              >
                {uploadedPhotoURL ? "Змінити фото" : "Вибрати фото"}
              </button>

              {uploadedPhotoURL && (
                <div className="mobile-photo-preview">
                  <img src={uploadedPhotoURL} alt="Завантажене фото" />
                </div>
              )}

              <div className="mobile-step-navigation">
                <button
                  onClick={() => setMobileStep("size")}
                  className="mobile-back-button"
                >
                  Назад
                </button>
                <button
                  onClick={() => setMobileStep("preview")}
                  className="mobile-next-button"
                  disabled={!uploadedPhotoURL}
                >
                  Далі
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Preview step */}
        {mobileStep === "preview" && (
          <div className="mobile-step-content">
            <h3>Попередній перегляд та замовлення</h3>

            {selectedSize && uploadedPhotoURL && (
              <div className="mobile-room-preview">
                <RoomWithUserPhoto
                  roomImageUrl={selectedSize.roompic}
                  userImageUrl={uploadedPhotoURL}
                  greenSquare={{
                    x: selectedSize["placeholder-X"],
                    y: selectedSize["placeholder-Y"],
                    width: selectedSize.width,
                    height: selectedSize.height,
                  }}
                />
              </div>
            )}

            <div className="mobile-order-summary">
              <p>
                <strong>Розмір:</strong> {selectedSize?.name}
              </p>
              <p>
                <strong>Ціна:</strong> {selectedSize?.price} UAH
              </p>
            </div>

            <div className="mobile-step-navigation">
              <button
                onClick={() => setMobileStep("upload")}
                className="mobile-back-button"
              >
                Назад
              </button>
              <button
                onClick={handleAddToCart}
                className="mobile-order-button"
                disabled={!isOrderButtonActive}
              >
                Додати в кошик
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  // ==== ORIGINAL DESKTOP VERSION ====
  // This preserves your exact original desktop implementation without changes
  return (
    <div className="roomlayout-container">
      {/* Left column for buttons */}
      <div className="roomlayout-left-column">
        <div className="roomlayout-dropdown">
          <button className="roomlayout-button">
            {selectedSize
              ? `${selectedSize.name} | Ціна: ${selectedSize.price} UAH`
              : "Обрати розмір"}
          </button>
          <div className="roomlayout-dropdown-content">
            {sizes.length > 0 ? (
              sizes.map((size) => (
                <button
                  key={size.name}
                  className="roomlayout-dropdown-item"
                  onClick={() => handleSizeSelect(size)}
                >
                  {size.name} - {size.price} UAH
                </button>
              ))
            ) : (
              <button className="roomlayout-dropdown-item" disabled>
                Завантажую розміри...
              </button>
            )}
          </div>
        </div>

        <button onClick={handleUploadClick} className="roomlayout-button">
          Завантажити фото
        </button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handlePhotoUpload}
        />

        {/* Display uploaded photo preview */}
        {uploadedPhotoURL && (
          <div className="roomlayout-photo-preview-inline">
            <img
              src={uploadedPhotoURL}
              alt="Uploaded"
              className="roomlayout-uploaded-photo-inline"
            />
          </div>
        )}

        <button disabled={true} className="roomlayout-button-disabled">
          Редактор (Disabled)
        </button>

        <button
          onClick={handleAddToCart}
          className="roomlayout-button"
          disabled={!isOrderButtonActive}
        >
          Замовити
        </button>

        {!isOrderButtonActive && (
          <p className="roomlayout-hint">Вибери розмір та завантаж своє фото</p>
        )}
      </div>

      {/* Right column for the room preview - Using Original Component for Desktop! */}
      <div className="roomlayout-right-column" id="target-section">
        {selectedSize && uploadedPhotoURL && (
          <OriginalRoomWithUserPhoto
            roomImageUrl={selectedSize.roompic}
            userImageUrl={uploadedPhotoURL}
            greenSquare={{
              x: selectedSize["placeholder-X"],
              y: selectedSize["placeholder-Y"],
              width: selectedSize.width,
              height: selectedSize.height,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default RoomLayout;
