import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./OrderConfirmation.css";

const OrderConfirmation = () => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(60);

  // Countdown timer that updates every second
  useEffect(() => {
    if (timeLeft <= 0) {
      navigate("/");
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // Clear the interval if the component unmounts
    return () => clearInterval(intervalId);
  }, [timeLeft, navigate]);

  // Handle button click to go back to homepage
  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="order-confirmation-container">
      <div className="order-confirmation-content">
        <img
          src="/img/Logo_girl_trans.png"
          alt="ArtMe Holst Logo"
          className="confirmation-logo"
        />

        <h1 className="confirmation-title">Замовлення успішно оформлено!</h1>

        <div className="confirmation-message">
          <p>Дякуємо за Ваше замовлення! Ми вже почали працювати над ним.</p>
          <p>
            Наш менеджер зв'яжеться з Вами найближчим часом для уточнення
            деталей.
          </p>
          <p>
            Номер Вашого замовлення збережено в історії в особистому кабінеті.
          </p>
        </div>

        <div className="additional-info">
          <p>Маєте запитання? Зв'яжіться з нами:</p>
          <div className="contact-icons">
            <a href="https://t.me/ARTME_holst" className="contact-link">
              <img src="/img/icons/tg.png" alt="Telegram" />
            </a>
            <a href="#" className="contact-link">
              <img src="/img/icons/vber.png" alt="Viber" />
            </a>
            <a
              href="https://instagram.com/artme_holst"
              className="contact-link"
            >
              <img src="/img/icons/ig.png" alt="Instagram" />
            </a>
          </div>
        </div>

        <div className="redirect-info">
          <p>
            Автоматичний перехід на головну сторінку через{" "}
            <span className="countdown-timer">{timeLeft}</span> секунд
          </p>
          <div className="progress-container">
            <div
              className="progress-bar"
              style={{ width: `${(timeLeft / 60) * 100}%` }}
            ></div>
          </div>
          <button onClick={handleGoHome} className="home-button">
            Повернутися на головну
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
