import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import DIY from "./pages/DIY";
import Calc from "./pages/Calc";
import Faq from "./pages/Faq";
import Styles from "./pages/Styles";
import Museum from "./pages/Gallery";
import { UserContextProvider } from "./components/UserContext";
import FooterInfo from "./components/FooterInfo";
import User from "./pages/User";
import "./App.css";
import OrderWithoutRender from "./pages/OrderWithoutRender";
import Cart from "./components/Cart";
import Price from "./pages/Price";
import ExperimentalBanner from "./components/ExperimentalBanner";
import Contact from "./pages/Contact";
import Other from "./pages/Other";
import AdminDashboard from "./components/admin/AdminDashboard";
import OrderConfirmation from "./pages/OrderConfirmation";
import Legal from "./pages/Legal";
import DeliveryPage from "./pages/DeliveryPage";
import DiscountsPage from "./pages/DiscountsPage";

function App() {
  return (
    <UserContextProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/diy" element={<DIY />} />
          <Route path="/styles" element={<Styles />} />
          <Route path="/gallery" element={<Museum />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/user" element={<User />} />
          <Route path="/order/1" element={<Calc />} />
          <Route path="/order/2" element={<OrderWithoutRender />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/price" element={<Price />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/other" element={<Other />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/order-confirmation" element={<OrderConfirmation />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/delivery" element={<DeliveryPage />} />
          <Route path="/discounts" element={<DiscountsPage />} />
        </Routes>
      </Router>
      <FooterInfo />
      <ExperimentalBanner />
    </UserContextProvider>
  );
}

export default App;
